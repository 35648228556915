<template>
  <MainLayout>
    <template v-slot:navbar-title>{{ fullName ? fullName : "" }}</template>
    <CreateFormLayout>
      <template v-slot:title__text>
        {{ currentRole }}
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <div class="data card">
        <p class="data__text">Личные данные</p>
      </div>

      <div class="form-wrap">
        <ValidationForm
          formClass="form-edit-profile card"
          v-slot:default="{ handleSubmit }"
        >
          <div class="inputs">
            <ValidationInputField
              rules="required"
              label="Фамилия"
              v-model="last_name"
              validate-name="фамилия"
            />
            <ValidationInputField
              rules="required"
              label="Имя"
              v-model="first_name"
              validate-name="имя"
            />
            <ValidationInputField label="Отчество" v-model="middle_name" />
            <ValidationInputField label="E-mail" v-model="email" />
            <div class="grid-column_1 flex-left">
              <a @click="$router.go(-1)" class="btn btn_color_white mr-3">
                Назад
              </a>
              <button
                @click.prevent="handleSubmit(editProfile)"
                class="btn btn_color_green"
              >
                Применить
              </button>
            </div>
          </div>
        </ValidationForm>
      </div>

      <div class="data card">
        <p class="data__text">Телефон</p>
      </div>

      <div class="form-wrap">
        <ValidationForm
          formClass="form-edit-profile card"
          v-slot:default="{ handleSubmit }"
        >
          <div class="inputs">
            <ValidationInputField
              label=""
              v-model="phone_number"
              validate-name="Телефон"
              mask="+7 (9##) ###-##-##"
              required
              rules="required|phone"
            />
            <div class="flex-ver-center relative">
              <p class="authoriz__text-input authoriz__text-input-register">
                Введите номер телефона на который вы хотите изменить свой
                действующий. После нажатия кнопки «Далее» на ваш действующий
                номер поступит звонок. Запомните последние 4 цифры исходящего
                вызова, введите их в модальном окне и нажмите кнопку "Применить"
              </p>
            </div>
            <div class="grid-column_1 flex-left">
              <button
                @click.prevent="handleSubmit(editProfileNumber)"
                class="btn btn_color_green"
                style="min-width: 150px"
              >
                Далее
              </button>
            </div>
          </div>
        </ValidationForm>
      </div>

      <v-dialog v-model="confirmNumberContinue" max-width="450">
        <v-card class="pa-6">
          <div class="object__item">
            <div class="object__body flex justify-center ">
              <v-card-title class="justify-center" style="text-align:center"
                >Введите последние 4 цифры <br />входящего номера</v-card-title
              >
              <FormInputBlock class="mt-2">
                <ValidationInputField
                  placeholder="####"
                  mask="####"
                  v-model="code"
                  label="Код"
                  validate-name="Код"
                />
              </FormInputBlock>
            </div>
            <v-card-actions class="justify-end mt-4">
              <button
                :class="{ disabled: disabled }"
                @click.prevent="confirmNumber()"
                class="btn btn_color_white mr-2"
              >
                Подтвердить
              </button>

              <button
                @click.prevent="confirmNumberContinue = false"
                class="btn btn_color_white"
              >
                Отмена
              </button>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </CreateFormLayout>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationForm from "@/components/ValidationField/ValidationForm";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "EditProfilePage",
  components: {
    ValidationInputField,
    FormInputBlock,
    ValidationForm,
    CreateFormLayout,
    MainLayout
  },
  data() {
    return {
      confirmNumberContinue: false,
      code: null, // код подтверждения
      disabled: true
    };
  },
  watch: {
    code: function(val) {
      if (val.length == 4) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    }
  },

  created() {
    this.isDateLoaded = true;
    this.$store.dispatch("fetchUserProfile").finally(() => {
      this.isDateLoaded = false;
    });
  },
  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRoleName;
    },

    fullName() {
      return this.$store.getters.getFullName;
    },
    phone_number: {
      get() {
        return this.$store.getters.getUserProfileNumber.value;
      },
      set(newValue) {
        this.$store.commit("SET_USER_PROFILE_NUMBER", newValue);
      }
    },
    last_name: {
      get() {
        return this.$store.getters.getUserProfileForm.last_name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditProfileValue", {
          fieldName: "last_name",
          value: newValue
        });
      }
    },
    first_name: {
      get() {
        return this.$store.getters.getUserProfileForm.first_name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditProfileValue", {
          fieldName: "first_name",
          value: newValue
        });
      }
    },
    middle_name: {
      get() {
        return this.$store.getters.getUserProfileForm.middle_name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditProfileValue", {
          fieldName: "middle_name",
          value: newValue
        });
      }
    },
    email: {
      get() {
        return this.$store.getters.getUserProfileForm.email.value;
      },
      set(newValue) {
        this.$store.commit("changeEditProfileValue", {
          fieldName: "email",
          value: newValue
        });
      }
    }
  },
  methods: {
    editProfile() {
      this.$store
        .dispatch("editUserProfile", {
          id: this.$store.getters.getUserId
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.profile)
          );
        });
    },

    editProfileNumber() {
      if (
        this.$store.getters.getUserProfileNumber.value ==
        this.$store.getters.getUserProfileForm.phone_number.value
      ) {
        alert("Номера повторяются. Нужно ввести другой!"); //TODO красиво уведомление
      } else {
        this.$store
          .dispatch("editUserNumberByUser", {
            phone_number: this.$store.getters.getUserProfileNumber.value
          })
          .then(() => {
            this.confirmNumberContinue = true;
          });
      }
    },

    confirmNumber() {
      this.$store
        .dispatch("confirmUserNumber", {
          code: this.code
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.profileNumber)
          );
        })
        .then(() => {
          this.$router.go(-1);
        })
        .finally(() => {
          this.confirmNumberContinue = false;
        });
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearUserProfile").then(() => next());
  }
};
</script>

<style scoped>
.disabled {
  pointer-events: none;
  border-color: #acacac;
  color: #acacac;
}
</style>
